import React from 'react';
import '../CSS/Work.css';

const experience = (Company, Position, Date, Bullets) => {
  const bulletList = Bullets.map((item, index) => <li key={index}>{item}</li>)
  return (
    <div className="card">
      <div className="worktitle">
        <h1 className='text-gradient2 ti'> {Position}</h1>
        <span className="justify-right mr-5">{Date}</span>
      </div>
      <h1 className="italic ml-5 comp">{Company}</h1>
      <ul className="list-disc ml-9 mb-5 mt-4">
        {bulletList}
      </ul>
    </div>
  );
}

export default function Work () {
  const PNC = experience('PNC Financial Services', 'Software Engineer Intern', 'May 2024 - August 2024',
    [
      /*'Enterprise Technology & Security Chief Information Office (CIO), Quality Engineering Inspections team.',
      'Worked on auditing software for testing strategies across 22 Lines of Business, grading roughly 25,000 employees.',
      'Designed the frontend for a data entry form and landing pages using React.js, Material UI, and Formik (data validation).',
      'Authored frontend unit tests for React components and data validation with Jest, achieving 90% average code coverage.',
      'Presented an enterprise web application for streamlining QA to 8 stakeholders. 50+ interns, engineers, and managers.',*/
    ]
  );
  const UMD = experience('University of Maryland Club Running', 'Web Developer', 'March 2023 – December 2023',
    [
      'Overhauled a PHP website to use JavaScript, React.js, and Tailwind CSS; Converted a SQL database to JSON.',
      'Orchestrated cost-effective domain and hosting solutions, reducing spending on the website by 85%.',
      'Monitored Google Search Console for web traffic statistics; improved Search Engine Optimization by 50%.',
      'Served among the club’s board of 7 officers, managing club records and resources for 100+ members.',
    ]
  );
  const Clark = experience('A. James Clark School of Engineering', 'Assistant to the Associate Director', 'August 2022 - August 2023',
    [
      'Developed a project management form and UI using VBA and Excel to organize 20+ ongoing construction projects.',
      'Assisted with laboratory renovations across all 16 buildings in the engineering school.',
      'Conducted data collection on airborne particles, collecting 120+ samples in a class 1000 cleanroom.',
      'Digitized and managed over 1500+ Graduate Student records for the 2022-2023 catalog.'
    ]
  );

  return (
    <div className='section' id='work'>
      <div className='experience'>
        <h1 className="text-5xl font-bold underline">Work Experience</h1>
        {PNC}
        {UMD}
        {Clark}
      </div>
    </div>
  );
};
