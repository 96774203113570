import React from 'react';
import { BiHomeAlt, BiBulb } from 'react-icons/bi';
import { BsClipboardData, BsBriefcase, BsChatSquare } from 'react-icons/bs';
import { Link } from 'react-scroll';

const link = (icon, link) => {
    return (
        <Link to={link} activeClass="active2" smooth="true" spy="true" className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center" >
            {icon}
        </Link>
    );
}

export default function Nav() {
    return (
        <nav className="fixed bottom-2 lg:bottom-5 w-full overflow-hidden z-50">
            <div className="container mx-auto">
                <div className="w-full bg-black/20 h-[70px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex justify-between items-center text-2xl text-white/70">
                    {link(<BiHomeAlt />, 'home')}
                    {link(<BiBulb />, 'about')}
                    {link(<BsClipboardData />, 'skills')}
                    {link(<BsBriefcase />, 'work')}
                    {link(<BsChatSquare />, 'contact')}
                </div>
            </div>
        </nav>
    );
};